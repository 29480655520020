import { createRouter, createWebHistory } from '@ionic/vue-router';
import eventBus from "@/event-bus";

import firebase from "firebase/app";
import "firebase/auth";
import Login from '@/pages/Login.vue'
import CustomersLogin from '@/pages/CustomersLogin.vue'


const routes = [
  {
    path: '/',
    component: () => import ('@/pages/Login.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/customers_login/:clave([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
    name: 'customers_login',
    component: CustomersLogin,   
    meta: {
      requiresAuth: true,
      requiresScope: "riesgos",
    },
    props: true,
}
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  window.fake_console(from, to, next);
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) {
    eventBus().emitter.emit("deeplink", to);
    window.fake_console(
      "Redireccion a login, intento pagina y no hay credencial"
    );
    next("/");
  } else {
    next()
  }
});

export default router
