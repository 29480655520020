<template>
    <ion-page>

          <ion-content>
            <ion-grid class="google">              
              <ion-row class="google ion-align-items-center ion-justify-content-center">
                <ion-button v-on:click="loginWithGoogle" fill="outline" size ="large" color="primary" class="login">
                    <ion-icon slot="start" icon="assets/google.svg"/>
                    Login 
                </ion-button>
              </ion-row>
              <ion-row class="google ion-align-items-start ion-justify-content-center">
                <ion-img src="img/icons/android-chrome-192x192.png"/> 
              </ion-row>
            </ion-grid>
          </ion-content>
    </ion-page>
</template>

<script>
    import {    IonPage, IonContent, IonButton,
                IonGrid,IonRow, IonIcon, IonImg

            } from '@ionic/vue';
    import {  openAlertaV2 } from  '@/utilities//global_functions';

    import { defineComponent } from 'vue';
    import { useRoute } from 'vue-router';

    export default defineComponent({
        name : "Login",
        components: {  IonPage, IonContent, IonButton,
            IonGrid,IonRow, IonIcon, IonImg
            },
        data() {
            return {
                new_url : undefined
            }
        },
        async mounted() {
                const route = useRoute();
                this.ruta = route.fullPath
                this.rutabase = route.path
                const query = route.query
                this.new_url = query.new_url
                console.log(route)
                console.log(route.query)
                await this.loginWithGoogle()
            }, 

        methods: {
            async loginWithGoogle() {
                const self = this                
                this.$firebase.auth().setPersistence(this.$firebase.auth.Auth.Persistence.LOCAL)
                    .then(function() {
                        const provider = new self.$firebase.auth.GoogleAuthProvider();
                        provider.setCustomParameters({
                            prompt: 'select_account'
                        });
                        self.$firebase
                            .auth()
                            .signInWithPopup(provider)
                            .then( (usr) => {
                                console.log('me ha devuelto el usuario ')
                                console.log(usr)
                                /** Hay usuario, obtenemos token y fijamos rol y datos del usuario  */
                                console.log('Login 48: '+JSON.stringify(usr.user.email))
                                self.$firebase.auth().currentUser.getIdToken(true).then(async (idToken) =>{
                                    self.$http.defaults.headers['Authorization'] = 'Bearer '+idToken
                                    /* 
                                        Desde aqui va al deeplink de la pagina que hará lo que toque. 
                                        faltaría una ruta default si llegas hasta aqui     
                                    */
                                   if (self.$deeplink) {
                                        self.$router.replace(self.$deeplink)    
                                    }
                                    else {
                                await openAlertaV2('Camino sin salida...', `
                                    Esta acción no lleva a ninguna pagina`) 
                        }
                                    
                                })
                            })
                            .catch(err => { alert('en el login '+JSON.stringify(err.message)); });
                    })
                    .catch(function(error){
                        console.log('Login 73 (Error) : ',JSON.stringify(error))
                    })
            }
        }
})

</script>

<style scoped>
    ion-grid.google {
        height: 70%;
        margin-top: 20%;
        margin-bottom: 10%;
        width: 90%;
    }
    ion-row.google {
        height: 50%;
    }
</style>