<template>
    <div>
    </div>
</template>


<script>
    import { defineComponent } from 'vue';
    import axios from 'axios';
    import {  openAlertaV2 } from  '@/utilities//global_functions';
    export default defineComponent({
            name : "CustomersLogin",
            props: ['clave'],
            async created() {
                try  {
                    const supRequest = await this.$http.get('/rebates_check')
                    const myParams = supRequest.data
                    window.fake_console('L15 : ', myParams)
                    const {rol, pf, uname, usr } = myParams.profil
                    const profil = {rol, perfil:pf, mail:usr, usr: uname}
                    const payload = {profil}
                    try {
                        if (profil) {
                            window.fake_console({payload})
                            window.fake_console(document.referrer)
                            //alert(document.referrer)
                            const destino = document.referrer
                            const dest_url = `https://customers.ges.com/setter/${this.clave}`
                            const resultados = await axios.post(dest_url, payload )
                            const results = resultados.data
                            window.fake_console(results)
                            window.location.href = destino

                        }
                        else {
                            throw new Error("Error al cargar los datos")
                        }

                    }
                    catch (ex) {
                        window.fake_console(ex)
                        await openAlertaV2('Error', JSON.Stringify(ex))
                    }                    
                }
                catch  {
                    window.fake_console('Error')
                }

}
    })
</script>
 