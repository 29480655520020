import { toastController, alertController} from '@ionic/vue';

const dNumber = (n)=>{
    return n ===undefined ? 0 : n 
}     

const openToast= async function (head, msg) {
    const toast = await toastController
        .create({
        header: head,
        message: msg,
        duration: 2000,
        position: 'middle',
        buttons: [
            {
            side: 'end',
            text: 'Ok',
            handler: () => {
                window.fake_console('ok');
                }}
        ]
        })
    await toast.present();
    //const { role } = await toast.onDidDismiss();
    //window.fake_console('onDidDismiss resolved with role', role);
    };


    const openAlertaV2  = async function(head, msg) {
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                cssClass: 'alerta-estandar',
                backdropDismiss: false, 
                buttons: [
                    {
                    text: 'Si',
                    handler: () => { 
                        window.fake_console('en el handler')
                    },
                    },
                ]
            })
        await alerta.present();      
        await alerta.onDidDismiss();
        return 
    }
		


    const openAlertaBinaria = async function(head, msg, bNo, bSi) {
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                backdropDismiss: false, 
                buttons: [
                    { 
                        text: bNo===undefined ? 'No' : bNo, 
                        role: 'cancel' 
                    },
                    { 
                        text: bSi===undefined ? 'Si' : bSi, 
                        role: 'confirm',
                     },
                ]
            })
        await alerta.present(); 
        const { role } = await alerta.onDidDismiss();
        if (role=='confirm'){return true  } 
        else { return false }
    }
		

export {
 dNumber,   openToast,  openAlertaV2 , openAlertaBinaria
}

