<template>
  <ion-app>
        <ion-content id="main-content" scroll-y ="true" >
            <router-view> </router-view>
        </ion-content>
  </ion-app>

</template>

<script>
import { IonApp, IonContent 
  } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  data() {
    return {
      usuario: this.$current_user_name, 
      deferredPrompt: null, 
      intentos : 0 
    }
  },  
  components: {
    IonApp,  IonContent
  }, 
   methods: { 
    async dismiss() {
      window.fake_console('app ','El usuario no ha querido ')
      this.deferredPrompt = null;
    },
    async install() {
      window.fake_console('app ','Instalando.... ')
      this.deferredPrompt.prompt();
    }, 
    getDevice() {
      const w = window.innerWidth 
      const h = window.innerHeight 
      return { 'ancho': w , 'largo' : h , 
        'tamanyo': w < 576 ? 0 : w < 900 ? 1 : w < 1200 ? 2 : 3, 
        'tipo' : w < 576 ? 'sm' : w < 900 ? 'md' : w < 1200 ? 'lg' : 'xl'}
    }
  }
});
</script>

import

/** General Styles  */
<style lang="scss">
  @import "@/theme/main-styles.scss";
</style>
