//const ADDRESS =   'http://localhost:3000'  
const ADDRESS =  'https://rac.ges.services' 
const debug = false;

import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';

import eventBus from "@/event-bus";
import {globalFilters} from './utilities/filters'


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';


import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';


/* Theme variables */
import './theme/variables.css';
import './registerServiceWorker'

/** Customizaciones datatable -han de ser globales- */

window.miconsola =   debug===false ?  function() {void(0)} : console.log
window.fake_console =  debug===false ?  function() {void(0)} : console.log



/** VARIABLES GLOBALS DEL PROYECTO, CARGAN UNA ÚNICA VEZ EN MEMORIA */

axios.defaults.baseURL = ADDRESS

axios.defaults.headers["Content-Type"] = "application/json"
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers["Access-Control-Allow-Origin"] = "*"
axios.mode = "no-cors"

/** FB config*/
firebase.initializeApp({
  apiKey: "AIzaSyAzwMsPdcrIbVEvqrqopkjpOLG2Vm3PjGA",
  authDomain: "appgescom.firebaseapp.com",
  projectId: "appgescom",
  storageBucket: "appgescom.appspot.com",
  messagingSenderId: "728244704694",
  appId: "1:728244704694:web:ced2e56799f4abb42a1d17",
  measurementId: "G-KX4GNXBZ0Q",
  databaseURL: "https://appgescom.firebaseio.com"
});

/** 
 * Logica de la aplicación 
 * El router no se inicializa hasta que tenemos 
 * un cambio de AuthState en firebase
 * Sino monta las paginas antes de tener el usuario 
 * */

let app = createApp(App)
          .use(IonicVue)
          .use(router);


/**
 * Variables globales 
 */
var self = app.config.globalProperties
self.$http = axios
self.$current_user = undefined
self.$current_user_name = undefined
self.$filters = globalFilters
self.$deeplink = undefined;


/**
 * Variable global para acceder a Firebase 
 * desde el conjunto de la aplicación 
 * sino acaba intentando instanciarla varias veces
 */
self.$firebase = firebase
window.fake_console('main ','RUTAS : '+JSON.stringify(self.$router.routes))

router.isReady().then(() => {
  window.fake_console('main ','App montada')
  app.mount('#app');
  
});

// Control de deeplinks
eventBus().emitter.on("deeplink", (dl) => {
  window.fake_console("en el evento " + dl);
  self.$deeplink = dl;
});